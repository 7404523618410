<template>
  <div>
    <transition
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <div class="chatRoom" @mouseover="enter" id="chatroom" v-if="chatFlag">
        <img src="../assets/mobile/收回.png" class="shouhui" @click="chatFlag = false" />
        <div class="top"></div>
        <h1>
          <div
            class="roomList"
            :class="chatFlag ? 'roomList' : ' roomList roomListClose'"
          >
            <p
              v-for="(item, index) in cheatList"
              :key="index"
              :class="index == value ? 'active' : ''"
              @click="chooseRoom(index)"
            >
              {{ item.name }}
            </p>
          </div>
        </h1>
        <div class="main">
          <div class="chat" id="chat">
            <div v-for="(item, index) in msgList[value]" :key="index">
              <div :class="item.name == $chat.role.name ? 'msgList2' : 'msglist'">
                <div class="name">{{ item.name }}:</div>
                <div class="sendMsg">{{ item.msg }}</div>
              </div>

              <!-- <div class="msgList" v-if="item.url">
                <div class="name">{{ item.name }}:</div>
                <a :href="item.url" target="_blank" class="sendMsg">{{ item.msg }}</a>
              </div> -->
            </div>
          </div>

          <div class="group" v-if="groupFlag">
            <h3>
              <h3>累计{{ num + playerList.length }}人登录</h3>
            </h3>
            <div v-for="(item, index) in playerList" :key="index" class="msg">
              <p>{{ item.name }}</p>
              <div style="flex: 1"></div>
              <img
                src="../assets/newImg/chatRoom/chat.png"
                class="logo"
                v-if="item.id != $chat.role.id && item.id < 10000"
                @click="onlyChat(item.name, item.name, item.id)"
              />
            </div>
          </div>
        </div>
        <div class="send">
          <input
            :placeholder="$t('info.lang.sendInfo')"
            v-model="input"
            @focus="keyFlag(true)"
            @blur="keyFlag(false)"
            ref="input"
          />

          <div class="send" @click="send">SEND</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { get_avaters } from "../assets/api/index";
export default {
  data() {
    return {
      cheatList: [{ name: this.$t("info.lang.world"), id: 0 }],
      playerList: [],
      value: 0,
      input: "",
      groupFlag: true,
      chatFlag: true,
      msgList: [[]],
      name: "",
      chatFlag: false,
      num: 0,
    };
  },
  methods: {
    chooseRoom(e) {
      this.value = e;
    },
    keyFlag(e) {
      console.log(123);
      this.Bus.$emit("keyFlag", e);
    },
    enter() {
      console.log("enter");
    },
    send() {
      if (!this.input) {
        this.$message.error(this.$t("info.lang.sendError"));
        return;
      }

      if (this.$chat.role.id > 10000) {
        this.$message.error("游客暂无权限聊天");
        return;
      }
      if (this.value == 0) {
        this.$chat.send(`${this.$chat.connectInfo.CMD_USER_CHATS},${this.input}`);
        // console.log(this.$chat.role.auth)

        // if(this.$chat.role.auth<4){
        //   this.$message.error('只有管理员才能公屏聊天')
        //   return
        // }
        // let url = `https://api.wh12345.net:9300/chats/?name=${this.$chat.role.name}&msg=${this.input}`;
        // let xhr = new XMLHttpRequest();
        // xhr.open("get", url, true);
        // xhr.responseType = "text";
        // xhr.send();

        document.o2.chat_pop(document.o2.player, this.input);

        // let url = `https://api.wh12345.net:9300/chats/?name=${this.$chat.role.name}&msg=${this.input}`;
        // let xhr = new XMLHttpRequest();
        // xhr.open("get", url, true);
        // xhr.responseType = "text";
        // xhr.send();
      } else {
        this.$chat.send(
          `${this.$chat.connectInfo.CMD_USER_CHAT},${this.cheatList[this.value].id},${
            this.input
          },${this.value}`
        );
        // var e = `chat,only,${this.$chat.role.name},${this.input},${
        //   this.$chat.role.name
        // },${this.cheatList[this.value].name}, ${this.value}`;

        // let xhr = new XMLHttpRequest();
        // xhr.open("get", url, true);
        // xhr.responseType = "text";
        // xhr.send();

        // let url = `https://api.wh12345.net:9300/chat/?name0=${
        //   this.$chat.role.name
        // }&name1=${this.cheatList[this.value].name}&value=${this.value}&msg=${this.input}`;
        // let xhr = new XMLHttpRequest();
        // xhr.open("get", url, true);
        // xhr.responseType = "text";
        // xhr.send();
      }

      this.input = "";
    },

    getFile() {
      var upload = document.getElementById("uploadjl");

      var file = upload.files[0];
      var that = this;
      if (that.value == 0) {
        that.$message.error("不能在公屏发送文件");
      } else {
        that.$o2Function.getMD5(file, function (md5) {
          let formData = new FormData();
          formData.append("md5", md5);
          axios
            .post("https://api.wh12345.net/istation/media_exist.php?", formData, {
              "Content-Type": "multipart/form-data",
            })
            .then((res) => {
              console.log(res);
              let formData = new FormData();
              formData.append("file", file);
              axios
                .post("https://api.wh12345.net/istation/media_upload.php", formData, {
                  "Content-Type": "multipart/form-data",
                })
                .then((res) => {
                  let fileurl = document.showroom.encode64(
                    document.showroom.utf16to8(res.data.url)
                  );

                  let filename = document.showroom.encode64(
                    document.showroom.utf16to8(res.data.name)
                  );
                  let url = `https://api.wh12345.net:9300/file/?name0=${
                    that.$chat.role.name
                  }&name1=${that.cheatList[that.value].name}&value=${
                    that.value
                  }&filename=${filename}&fileurl=${fileurl}`;

                  // tfile({
                  //   name0:that.$chat.role.name,
                  //   name1:that.cheatList[that.value].name,
                  //   value:that.value,
                  //   filename:res.data.name,
                  //   fileutl:res.data.url
                  // })
                  let xhr = new XMLHttpRequest();
                  xhr.open("get", url, true);
                  xhr.responseType = "text";
                  xhr.send();
                });
            });
        });
      }
    },
    onlyChat(a, b, c) {
      console.log(a, b);
      if (this.$chat.role.name == b) {
        console.log("不能与自己聊天");
        return;
      }
      var found = this.cheatList.filter((el) => el.name === b).length > 0; // .length returns 1 if id exist in an array

      if (!found) {
        this.cheatList.push({
          name: a,
          id: c,
        });
      }
      var value = this.cheatList.findIndex((item) => item.name === b);
      this.value = value;
    },

    to_footer() {
      var div = document.getElementById("chat");
      setTimeout(() => {
        div.scrollTop = div.scrollHeight;
      }, 100);
    },
  },
  created() {
    get_avaters().then((res) => {
      console.log(res);
      this.num = parseInt(res.nums);
    });
    var that = this;
    document.onkeydown = function (el) {
      if (el.keyCode == 13) {
        if (that.chatFlag) {
          that.send();
          that.$refs.input.blur();
        }
      } else {
      }
    };
    this.Bus.$on("chatFlag", (e) => {
      console.log(this.chatFlag);
      this.chatFlag = true;
    });
    this.Bus.$on("msgList", (e) => {
      this.msgList = e;
      this.to_footer();
    });

    this.Bus.$on("playerList", (e) => {
      this.playerList = e;
    });

    this.Bus.$on("changeName", (e) => {
      console.log(e);
      console.log(this.playerList);
      var found = this.playerList.findIndex((el) => el.id == e.id);
      if (found) {
        this.playerList[found].name = e.data;
      }
    });

    this.Bus.$on("reciveMsg", (e) => {
      this.name = this.$chat.role.name;
      var found = this.cheatList.filter((el) => el.name === e.from).length > 0;
      if (!found) {
        this.msgList.push([]);
        this.cheatList.push({
          name: e.name,
          id: e.from,
        });
      }
      var value = this.cheatList.findIndex((item) => item.name === e.from);
      this.value = value;
      this.msgList[value].push({
        name: e.name,
        msg: e.msg,
        url: e.url,
      });
      this.$chat.msgList = this.msgList;
      this.to_footer();
    });
  },
};
</script>

<style scoped lang="scss">
.chatRoom {
  width: 300px;
  height: 100%;
  background: url("../assets/mobile/chatbg.png");
  background-size: 100% 100%;
  border-radius: 4px;
  position: absolute;
  right: 0px;
  bottom: 0;
  z-index: 2;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  transition: 0.5s;
  z-index: 10001;
  h1 {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .roomList {
      width: 350px;
      display: flex;
      align-items: center;
    }
    .roomListClose {
      width: 160px;
      overflow: hidden;
    }
    p {
      width: 50px;
      height: 24px;

      border-radius: 2px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      text-align: center;
      color: #afafaf;
      margin-left: 8px;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      padding: 0 5px;
    }
    .active {
      color: #fff;
      background: url("../assets/mobile/chatbg.png");
      transition: 0.5s;
      background-size: 100% 100%;
    }
    .icon {
      width: 24px;
      height: 24px;
      float: right;
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .main {
    height: calc(100% - 145px);
    display: flex;
    margin-bottom: 10px;
    .chat {
      overflow-y: scroll;
      width: 100%;
      flex: 1;

      .msglist,
      .msgList2 {
        flex-wrap: wrap;
        font-size: 14px;
        margin-bottom: 8px;
        text-align: left;
        .name {
          margin: 12px 12px 5px 12px;
          color: white;

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        .sendMsg {
          color: #fff;
          flex: 1;
          text-align: left;
          margin: 0 12px;
          word-wrap: break-word;
          max-width: 180px;
          padding: 5px;
          font-size: 12px;
          background: rgba($color: #8aa5c0, $alpha: 0.7);
          display: inline-block;
          // display: flex;
          // align-items: stretch;
        }
      }
      .msgList2 {
        text-align: right;
      }
    }
    .chat::-webkit-scrollbar,
    .group::-webkit-scrollbar {
      width: 4px;
      opacity: 0.6;
    }
    .chat::-webkit-scrollbar-track,
    .group::-webkit-scrollbar-track {
      background: #ffffff;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }

    .chat::-webkit-scrollbar-thumb,
    .group::-webkit-scrollbar-thumb {
      background-color: #1dd3b0;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }

    .group {
      width: 120px;
      overflow-y: scroll;
      h3 {
        font-size: 12px;
        color: #fff;
        text-align: center;
        margin-bottom: 6px;
      }
      .msg {
        display: flex;
        width: 110px;
        height: 28px;

        background: rgba($color: #4c749c, $alpha: 0.7);
        border-radius: 2px;
        margin-bottom: 6px;
        margin-left: 5px;
        align-items: center;
        .circle {
          width: 6px;
          height: 6px;
          background: #fff;

          border-radius: 50%;
          margin-left: 5px;
        }
        p {
          font-size: 13px;
          color: #ffffff;
          margin-left: 5px;
          width: 100px;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        .logo {
          margin-right: 5px;
          cursor: pointer;
          width: 20px;
        }
      }
    }
  }
  .send {
    display: flex;
    margin: 0 10px;
    justify-content: center;
    input {
      width: 216.34px;
      height: 27.91px;
      background: rgba(0, 0, 0, 0);
      border: 0.7px solid #ffffff;
      padding: 0 10px;
      margin-right: 10px;
      color: #fff;
    }

    .send {
      width: 98.05px;
      height: 27.91px;
      background: linear-gradient(180deg, #fffbf7 0%, #f5fbff 100%);

      font-size: 12px;
      font-family: BMW Group, BMW Group-Bold;
      font-weight: 700;
      text-align: left;
      color: #3e5366;
      line-height: 28.96px;
      letter-spacing: 0.63px;
      text-align: center;
    }
  }
}
.closeRoom {
  width: 200px;
  transition: 0.5s;
  transform: translateY(207px);
}
.upload {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
#uploadjl {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  cursor: pointer;
  opacity: 0;
}

.chat1 {
  position: fixed;
  right: 90px;
  top: 40px;
  width: 40px;
}

.top {
  width: 100%;
  height: 40px;
}

.chat::-webkit-scrollbar,
.group::-webkit-scrollbar {
  display: none;
}

.shouhui {
  position: absolute;
  top: 30px;
  right: 10px;
  width: 30px;
}
</style>
