<template>
  <div id="mainview">
    <el-main>
      <div id="container" @keydown="onKeyDown($event)" @keyup="onKeyUp($event)"></div>
    </el-main>
  </div>
</template>
<script>
import Bus from "../../common/js/bus";
import * as THREE from "three/build/three.module";
import { login } from "../assets/api/index.js";
import { screenVideo } from "../../common/js/screenVideo";
//import DPlayer from 'dplayer'
// import func from 'vue-editor-bridge';
export default {
  name: "MainView",
  data() {
    return {
      flag: false,
      desc: "3D View",
      O2: {},
      selObj: {},
      SCREEN_WIDTH: 800,
      SCREEN_HEIGHT: 600,
      btnDown: {
        x: 0,
        y: 0,
      },
      mouse: {
        x: 0,
        y: 0,
      },
      mouse_down: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    start3D() {
      var that = this;
      let container = document.getElementById("container");
      this.SCREEN_WIDTH = container.clientWidth;
      this.SCREEN_HEIGHT = container.clientHeight;
      var _o2 = document.o2vr;
      var screen;
      var screen_video;
      _o2.init(container);

      if (this.O2Builder) {
        let builder = this.O2Builder;
        builder.init(_o2);
      }
      var olyurl = [
        "https://o2res.oss-cn-beijing.aliyuncs.com/%E6%AC%A7%E8%8E%B1%E9%9B%85/%E6%96%B0/%E5%BA%8F%E5%8E%85619.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001655697275&Signature=kUB2grZ4VFczfYqw%2Ftq9U1ysUXU%3D",
        "https://o2res.oss-cn-beijing.aliyuncs.com/%E9%9D%92%E5%B2%9B%E5%BD%AD%E5%8D%9A/%E9%9D%92%E5%B2%9B%E5%BD%AD%E5%8D%9Awebgl_v1.0.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001666853818&Signature=592b9Ljr48%2B2n6cd%2FI7Mvcq02jo%3D",
        "https://o2res.oss-cn-beijing.aliyuncs.com/%E6%AC%A7%E8%8E%B1%E9%9B%85/%E6%96%B0/%E8%B5%9B%E9%81%93619.zip?OSSAccessKeyId=LTAI4G4zQbTdurvEbr1rR9zS&Expires=36001655697324&Signature=dJMccQhQK6sxcVhYI2yGOyOJleI%3D",
      ];
      var arrOriginPos = [
        [45, 11, 7024, 342, 2000, 10306, 3.1426],
        [0, 7000, -24550, 0, 9557, -27483, 0],
        [55, 11, 11317, -33, 2215, 15373, 3.1426],
      ];
      var i = 1;
      let ppp = this.O2vr.load_zip_scene(olyurl[i]);

      _o2.originPos = arrOriginPos[i];
      // let ppp = this.O2vr.load_o2_scene(
      //   "http://localhost/web/static/builder/scene.json"
      // );

      {
        let last_mouse_down = Date.now();
        _o2.script = {};
        _o2.gifs = [];
        let scripts = _o2.script;
        _o2.dist_appear = false;
        scripts["init"] = function init() {
          {
            //表情们
            {
              let promise = _o2.import_object_url("https://o2vr.net/gif/gz.zip");
              promise.then((obj3) => {
                if (obj3) {
                  _o2.gifs[0] = obj3;
                }
              });
            }

            {
              let promise = _o2.import_object_url("https://o2vr.net/gif/zan.zip");
              promise.then((obj3) => {
                if (obj3) {
                  _o2.gifs[1] = obj3;
                }
              });
            }
          }
          console.log("inited");
          screen = _o2.search_obj_by_name("screen");
          _o2.screen = screen;
          _o2.logo = _o2.search_obj_by_name("Logo");
          if (screen) {
            screen.material.color.setRGB(1, 1, 1);
            screen.material.lightMap = _o2.map_white;
            screen.visible = false;
          }
          if (_o2.init_play) _o2.init_play();
          _o2.on_loading_msg("正在下载:", String(Math.ceil(100)), "%");
          let arr = _o2.originPos;
          _o2.cameraControls.target.set(arr[0], arr[1] + 1700, arr[2]);
          _o2.camera.position.set(arr[3], arr[4], arr[5]);
          _o2.cameraControls.panSpeed = 0.5;
          _o2.cameraControls.maxDistance = 5000;
          _o2.cameraControls.minDistance = 10;
          // _o2.cameraControls.maxPolarAngle = Math.PI - Math.PI / 2;
          // _o2.cameraControls.minPolarAngle = Math.PI / 6;
          _o2.scene.add(_o2.playerMgr.playerNode);

          _o2.float_animation = _o2.animation.get_timeline_by_name("auto_ani");
          if (_o2.float_animation) _o2.float_animation.play();
          _o2.arrow_ani = _o2.animation.get_timeline_by_name("arrow");
          if (_o2.arrow_ani) _o2.arrow_ani.play();

          screen_video = new screenVideo();
          screen_video.setScreen(screen);
          _o2.screen_video = screen_video.videoElement;

          Bus.$on("playVideo", (url) => {
            screen_video.playVideo(url);
          });
          var info = JSON.parse(localStorage.getItem("info"));
          if (!info.type) {
            login({
              acount: info.acount,
              psd: info.psd,
            }).then((res) => {
              console.log(res);
              if (res.code == 1) {
                that.$message.error(res.error);
                setTimeout(() => {
                  localStorage.removeItem("info");
                  location.reload();
                }, 500);

                return;
              }

              if (res.black == 1) {
                that.$message.error("你已被拉入黑名单");
                setTimeout(() => {
                  localStorage.removeItem("info");
                  location.reload();
                }, 500);
              }
              if (res.info) {
                that.$chat.role.name = res.name;
                that.$chat.role.id = res.id;
              } else {
                that.$chat.role = {
                  name: res.name,
                  id: res.id,
                };
              }

              that.$chat.role.auth = res.auth;
              that.$chat.role.group_id = res.group_id;

              (that.$chat.user_group = res.group_id), that.$chat.login(that.$chat.role);
            });
          } else {
            that.$chat.role.name = info.name;
            that.$chat.role.auth = 0;
            that.$chat.user_group = -1;
            that.$chat.role.type = true;

            that.$chat.login(that.$chat.role);
          }
        };

        // if (!info) {
        //   that.$chat.role.name = info.name;
        //   that.$chat.role.auth = 0;
        //   that.$chat.user_group = -1;
        //   that.$chat.role.type = true;
        // }

        function on_close_lable() {
          if (_o2.current_solution != "") {
            active_solution("");
          }
          _o2.active_software("");
        }
        _o2.on_close_lable = on_close_lable;
        function add_lable_to_char(object, name) {
          let canvas = document.createElement("canvas");
          canvas.width = 160;
          canvas.height = 32;
          const drawingContext = canvas.getContext("2d");
          drawingContext.fillStyle = "#000000";
          drawingContext.globalAlpha = 0.8;
          drawingContext.fillRect(0, 0, 160, 32);
          drawingContext.globalAlpha = 1;
          drawingContext.fillStyle = "#ffffff";
          drawingContext.font = "24px Georgia";
          drawingContext.textAlign = "center";
          drawingContext.fillText(name, 80, 24);

          let map = new THREE.CanvasTexture(canvas);
          let sprite = new THREE.Sprite(
            new THREE.SpriteMaterial({ map: map, color: "#ffffff" })
          );
          sprite.position.set(0, 1900, 0);
          sprite.scale.set(600, 120, 1);
          sprite.material.depthWrite = false;
          object.add(sprite);
          return sprite;
        }
        _o2.add_lable_to_char = add_lable_to_char;
        function update_char_name(obj, name) {
          for (let i = 0; i < obj.children.length; i++) {
            let child = obj.children[i];
            if (child.type == "Sprite") {
              obj.remove(child);
              break;
            }
          }
          _o2.add_lable_to_char(obj, name);
        }
        _o2.update_char_name = update_char_name;
        function add_video_stream_to_char(obj, id) {
          if (!obj) return;
          if (obj.children) {
            for (let i = 0; i < obj.children.length; i++) {
              let e = obj.children[i];
              if (e.name == "videoSprite" && e.isSprite) {
                return;
              }
            }
          }
          let div = document.getElementById(id);
          console.log(div);
          if (div) {
            let arr = div.getElementsByTagName("video");
            console.log(arr.length);
            if (arr && arr.length > 0) {
              let video = arr[0];
              let tex = new THREE.VideoTexture(video);
              let sprite = new THREE.Sprite(
                new THREE.SpriteMaterial({ map: tex, color: "#ffffff" })
              );
              sprite.name = "videoSprite";
              obj.add(sprite);
              sprite.position.set(0, 2500, 0);
              sprite.scale.set(800, 600, 1);
            }
          }
        }
        _o2.add_video_stream_to_char = add_video_stream_to_char;
        function onRemoteStreamAdd(id) {
          if (_o2.players) {
            for (let i = 0; i < _o2.players.length; i++) {
              let e = _o2.players[i];
              if (e.name == id) {
                _o2.add_video_stream_to_char(e.object, id);
              }
            }
          }
        }
        _o2.onRemoteStreamAdd = onRemoteStreamAdd;

        function onLocalStreamAdd() {
          if (_o2.player) {
            _o2.add_video_stream_to_char(_o2.player, "local_stream");
          }
        }
        _o2.onLocalStreamAdd = onLocalStreamAdd;
      }

      ppp.then(() => {
        console.log("load ended");
        if (_o2.onSceneLoaded) {
          _o2.onSceneLoaded();
        }
      });
    },
  },
  created() {},
  mounted() {
    //在模板编译完成后执行
    this.start3D();
  },
};
</script>

<style>
#mainview,
#container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.el-main {
  padding: 0 !important;
  background-color: #e9eef3;
  color: #333;
  height: 100%;
}
#videoElement {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 200px;
  height: 200px;
  background: black;
  display: none;
}
</style>
