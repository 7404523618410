<template>
  <div class="index">
    <MainView />
    <O2vr />
    <Play :floor="'Rectangle294579029'" />
    <Function v-if="type == 1" />
    <Box />
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <Chat @close="chatFlag = false" @num="num++" :flag="chatFlag" v-if="type == 1" />
    </transition>

    <Client v-if="type == 1" />
    <Tishi v-if="type == 0" />

    <vcircle v-if="type == 1" />
    <!-- <div
      class="chat"
      @click="
        chatFlag = true;
        num = 0;
      "
    >
      <div class="circle" v-if="num > 0">
        {{ num }}
      </div>
      <img src="../assets/newImg/mobile/chat.png" />
    </div> -->

    <!-- <div class="test">
      <img @click="jushou" src="../assets/newImg/jushou.png" v-if="jushouFlag" />
      <img @click="qili" src="../assets/newImg/qili.png" v-if="sitFlag" />
    </div> -->
  </div>
</template>

<script>
import MainView from "../components/MainView.vue";
import O2vr from "../components/loading.vue";
import Play from "../components/play_o2report.vue";
import Function from "../mobilecomponent/function.vue";
import Chat from "../mobilecomponent/chat.vue";
import Client from "../mobilecomponent/client.vue";
import Box from "../components/box.vue";
import Tishi from "../components/tishi.vue";
import vcircle from "../components/circle.vue";
export default {
  data() {
    return {
      flag: false,
      chatFlag: false,
      num: 0,
      jushouFlag: false,
      sitFlag: false,
      type: 0,
    };
  },
  components: { MainView, O2vr, Play, Function, Chat, Client, Box, Tishi, vcircle },
  methods: {
    qili() {
      this.Bus.$emit("stand", "");
    },
    jushou() {
      if (this.$player.handPanel.visible == true) {
        this.$message.error("无法重复举手");
        return;
      }
      this.$chat.ws.send(`handUp,${this.$chat.role.name},${this.$client.roomId}`);
      this.$player.handPanel.visible = true;
    },
  },
  created() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.flag = true;
    }
    this.Bus.$on("loginError4", () => {
      this.$message.error(this.$t("info.lang.loginError4"));
      setTimeout(() => {
        localStorage.removeItem("info");
        location.reload();
      }, 500);
    });
    this.type = document.o2.dreamType;
    this.$player.onStateChg.push((state) => {
      if (state == "sit") {
        this.sitFlag = true;

        if (this.$client.type == 1) {
          this.jushouFlag = true;
        }
      } else {
        this.sitFlag = false;
        this.jushouFlag = false;
      }
    });
  },
  beforeCreate() {
    // if (!localStorage.getItem("info") && !localStorage.getItem("token")) {
    //   this.$router.push({ name: "mobileLogin" });
    // }
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
}

.chat {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
// .circle1 {
//   width: 20px;
//   height: 20px;
//   background: #f53939;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: #fff;
//   position: absolute;
//   right: -5px;
//   top: -5px;
// }

.test {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 20px;
  display: flex;
  justify-content: center;
  img {
    width: 30px;
    margin: 0px 10px;
    cursor: pointer;
  }
}
</style>
