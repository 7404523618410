<template>
  <div class="test"></div>
</template>

<script>
export default {
  data() {
    return {
      timmer: "",
    };
  },
  methods: {
    screenTest() {
      var width = document.documentElement.clientWidth;
      if (width < 1000 && width > 500) {
        this.$router.push({ name: "mobileLogin" });
        location.reload();
      }
    },
  },
  created() {
    console.log(123);
    this.timmer = setInterval(() => {
      this.screenTest();
    }, 100);
  },
  destroyed() {
    clearInterval(this.timmer);
  },
};
</script>

<style lang="scss" scoped>
.test {
  width: 100%;
  height: 100%;
  background: url("../assets/newImg/mobile/test.jpg");
  background-size: 100% 100%;
  position: absolute;
}
</style>
