import { render, staticRenderFns } from "./change.vue?vue&type=template&id=40ea06cc&scoped=true&"
import script from "./change.vue?vue&type=script&lang=js&"
export * from "./change.vue?vue&type=script&lang=js&"
import style0 from "./change.vue?vue&type=style&index=0&id=40ea06cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40ea06cc",
  null
  
)

export default component.exports