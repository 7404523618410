<template>
  <div>
    <transition
      enter-active-class="animated fadeInDown"
      leave-active-class="animated fadeOutUp"
    >
      <div
        class="shadow"
        :style="flag ? '' : 'transform: translateY(-800px);'"
        id="shadow"
      >
        <div class="cFunction">
          <img
            :class="!flag ? 'trans' : 'trans trans1'"
            src="../assets/newImg/trans.png"
            @click="flag = !flag"
          />
          <svg-icon
            icon-class="bmwicon-语音"
            @click="open(1)"
            v-if="yuyinFlag"
            class="img1 active"
            style="color: #536b8e"
          />
          <svg-icon icon-class="bmwicon_禁用语音" @click="open(1)" v-else class="img1" />

          <svg-icon
            icon-class="bmwicon-摄像头"
            @click="open(2)"
            v-if="shipinFlag"
            class="img1 active"
            style="color: #536b8e"
          />
          <svg-icon
            icon-class="bmwicon_禁用摄像头"
            @click="open(2)"
            v-else
            class="img1"
          />

          <!-- <svg-icon
            icon-class="bmwicon-屏幕分享"
            @click="open(3)"
            v-if="screenFlag"
            class="img1 active"
            style="color: #536b8e"
          />
          <svg-icon
            icon-class="bmwicon_禁用屏幕共享"
            @click="open(3)"
            v-else
            class="img1"
          /> -->
        </div>
        <div id="restream"></div>
        <transition
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
        >
          <div
            id="shareScreen"
            :class="playValue == -2 ? 'videoBox pos' : 'videoBox'"
            v-if="shareFlag"
            @click="playValue = -2"
          >
            <img
              src="../assets/newImg/function/close.png"
              class="close"
              v-if="playValue == -2"
              @click.stop="playValue = 999"
            />
            <div class="msg">
              <p>{{ sharename }}正在分享屏幕</p>

              <!-- <img src="../assets/newImg/function/qp.png" @click="qpFlag = !qpFlag" /> -->
            </div>
          </div>
        </transition>
        <div
          :class="playValue == -1 ? 'videoBox pos' : 'videoBox'"
          @click="playValue = -1"
          v-show="localFlag && (yuyinFlag || shipinFlag)"
        >
          <img src="../assets/newImg/function/background.png" class="img" />
          <img
            src="../assets/newImg/function/close.png"
            class="close"
            v-if="playValue == -1"
            @click.stop="playValue = 999"
          />

          <div
            id="local_stream"
            autoplay
            style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 99"
          ></div>
          <div class="msg">
            <p>{{ $chat.role.name }}</p>
            <div class="iconList">
              <svg-icon
                icon-class="bmwicon-语音"
                v-if="yuyinFlag"
                style="margin-right: 10px; color: #536b8e"
              />
              <svg-icon icon-class="bmwicon_禁用语音" v-else style="margin-right: 10px" />

              <svg-icon
                icon-class="bmwicon-摄像头"
                v-if="shipinFlag"
                style="margin-right: 10px"
              />
              <svg-icon
                icon-class="bmwicon_禁用摄像头"
                v-else
                style="margin-right: 10px"
              />
            </div>
          </div>
        </div>
        <div class="msgList" style="position: absolute">
          <div
            class="videoBox"
            v-for="(item, index) in list"
            :key="index"
            @click="playValue = index"
          >
            <img
              src="../assets/newImg/function/close.png"
              class="close"
              v-if="playValue == index"
              @click.stop="playValue = 999"
            />

            <div class="msg">
              <p>
                {{ $chat.searchName(item.userId) }}
              </p>
              <div class="iconList">
                <svg-icon icon-class="bmwicon-语音" v-if="item.audioMuted" />
                <svg-icon icon-class="bmwicon-语音关闭" v-else />
                <svg-icon icon-class="bmwicon-摄像头" v-if="item.videoMuted" />
                <svg-icon icon-class="bmwicon-摄像头关闭" v-else />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { client } from "../assets/api/index";
export default {
  data() {
    return {
      flag: false,
      list: [],
      yuyinFlag: false,
      shipinFlag: false,
      screenFlag: false,
      playValue: 999,
      shareFlag: false,
      sharename: "",
      qpFlag: false,
      localFlag: false,
      auth: 0,
    };
  },
  methods: {
    open(e) {
      if (!this.$client.clientFlag) {
        this.$message.error(this.$t("info.lang.clientError"));
        return;
      }

      if (e == 1) {
        if (this.$chat.role.auth < 3 && !this.$client.ykflag) {
          this.$message.error("您暂无权限");
          return;
        }
        this.yuyinFlag = !this.yuyinFlag;
        this.$client.muteAudio(this.yuyinFlag);
      } else if (e == 2) {
        if (this.$chat.role.auth < 3) {
          this.$message.error("您暂无权限");
          return;
        }
        if (!document.client.cameraFlag) {
          this.$message.error(this.$t("info.lang.videoError"));
          return;
        }
        this.shipinFlag = !this.shipinFlag;
        this.$client.muteVideo(this.shipinFlag);
      } else {
        if (this.$chat.role.auth < 3 && !this.$client.ykflag) {
          this.$message.error("您暂无权限");
          return;
        }
        if (this.$client.share && !this.screenFlag) {
          console.log(this.$client.share, !this.screenFlag);
          this.$message.error(this.$t("info.lang.shareError"));
          return;
        }
        this.screenFlag = !this.screenFlag;
        this.$client.shareScreen(this.screenFlag);
      }
    },
    check(e) {
      if (document.o2.meettingType == true) {
        this.$client.join(e);
        return;
      }
      var id = "";
      if (e == 5001) {
        id = 6;
      } else if (e == 5002) {
        id = 7;
      } else if (e == 5003) {
        id = 8;
      } else {
        var str = String(e);
        id = str.charAt(str.length - 1);
      }

      // https://o2vr.net/istation

      //https://api.wh12345.net/istation/

      client({ id: id }).then((res) => {
        var status = this.getStatus(res.data);
        if (status == 0 || status == 2) {
          this.$message.error("会议室暂未开启");
        } else if (status == 3) {
          this.$message.error("会议室已过期");
        } else {
          this.$prompt("请输入邀请码", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
            .then(({ value }) => {
              console.log(value);
              if (value == res.data.invite_code) {
                this.$message({
                  type: "success",
                  message: "正在加入房间",
                });
                this.$client.join(e);
              } else {
                this.$message.error("验证码错误");
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },

    getStatus(e) {
      if (e.time0 == null) {
        return 0; //空闲
      }
      var time = new Date().getTime();

      if (time > Number(e.time0) && time < Number(e.time1)) {
        return 1; //正在使用
      }

      if (time < e.time1) {
        return 2; //已预约
      }

      if (time > e.time1) {
        return 3;
        //已过期;
      }
    },
  },
  created() {
    this.Bus.$on("clientFlag", (e) => {
      this.flag = true;
    });
    this.Bus.$on("zhuchirenhuifu", (e) => {
      if (e) {
        this.$client.ykflag = true;
        this.$message({
          type: "success",
          message: "您的权限申请成功",
        });
      } else {
        this.$message.error("您的请求已被拒绝");
      }
      // this.$chat.ws.send(`handDown,${this.$chat.role.name},${this.$client.roomId}`);
      // this.$player.handPanel.visible = false;
    });
    this.Bus.$on("clientLogin", (e) => {
      if (this.$chat.user_status[2] == 1) {
        this.$message.error(`您已被禁止加入房间`);
        return;
      }

      if (
        e == 7005 ||
        e == 7004 ||
        e == 7003 ||
        e == 7002 ||
        e == 7001 ||
        e == 5001 ||
        e == 5002 ||
        e == 5003
      ) {
        if (this.flag) return;
        this.check(e);
        return;
      }

      if (e) {
        this.$client.join(e);
      }
    });

    this.Bus.$on("manage", (e) => {
      console.log(e);
      var manageList = e.split("-");
      var yuyinFlag = Number(manageList[0]);
      var shipinFlag = Number(manageList[1]);
      var roomFlag = Number(manageList[2]);
      var screenFlag = Number(manageList[3]);
      var authFlag = Number(manageList[4]);
      this.$chat.user_status = [yuyinFlag, shipinFlag, roomFlag, screenFlag, authFlag];

      this.$alert(
        `<div>语音权限:<span style="${
          yuyinFlag == 1 ? "color:red" : "color:rgb(69, 216, 79)"
        }">${yuyinFlag == 1 ? "关闭" : "开启"}</span></div>
        <div>视频权限:<span style="${
          shipinFlag == 1 ? "color:red" : "color:rgb(69, 216, 79)"
        }">${shipinFlag == 1 ? "关闭" : "开启"}</span></div>
        <div>进入房间权限:<span style="${
          roomFlag == 1 ? "color:red" : "color:rgb(69, 216, 79)"
        }">${roomFlag == 1 ? "关闭" : "开启"}</span></div>
        <div>屏幕共享权限:<span style="${
          screenFlag == 1 ? "color:red" : "color:rgb(69, 216, 79)"
        }">${screenFlag == 1 ? "关闭" : "开启"}</span></div>`,
        "当前您的权限已被管理员更改",
        {
          dangerouslyUseHTMLString: true,
        }
      );
      if (yuyinFlag == 1 && yuyinFlag) {
        console.log(123);
        this.yuyinFlag = false;
        this.$client.muteAudio(false);
      }

      if (shipinFlag == 1 && this.shipinFlag) {
        if (!this.$client.cameraFlag) {
          this.$message.error(this.$t("info.lang.videoError"));
          return;
        }
        this.shipinFlag = false;
        this.$client.muteVideo(false);
      }

      if (screenFlag == 1 && this.screenFlag) {
        if (this.$client.share && !this.screenFlag) {
          this.$message.error(this.$t("info.lang.shareError"));
          return;
        }
        this.screenFlag = false;
        this.$client.shareScreen(false);
      }

      if (roomFlag == 1 && this.flag) {
        this.$client.loginOut();
        document.getElementById("local_stream").innerHTML = "";
        this.flag = false;
        this.shareFlag = false;
        this.yuyinFlag = false;
        this.shipinFlag = false;
        this.screenFlag = false;
      }
    });
    this.Bus.$on("clientOut", (e) => {
      console.log("退出房间");
      document.getElementById("local_stream").innerHTML = "";
      this.list = [];
      this.$client.loginOut();
      this.flag = false;
      this.shareFlag = false;
      this.yuyinFlag = false;
      this.shipinFlag = false;
      this.screenFlag = false;

      this.$message.error("您已退出房间");
    });
    this.Bus.$on("localFlag", (e) => {
      this.localFlag = e;

      console.log(this.localFlag, this.flag);
    });
    this.Bus.$on("listChange", (e) => {
      if (e.length > 0) {
        // this.list = e;
        e.forEach((stream) => {
          this.list.find(function (value) {
            if (value.userId == stream.userId) {
              value.audioMuted = stream.audioMuted;
              value.hasAudio = stream.hasAudio;
              value.hasVideo = stream.hasVideo;
              value.videoMuted = stream.videoMuted;
            }
          });
        });
      }
    });
    this.Bus.$on("addlist", (e) => {
      this.list.push({
        userId: e.getUserId(),
        audioMuted: true,
        videoMuted: true,
        hasAudio: false,
        hasVideo: false,
      });
      var name = e.getUserId();
      var node = document.createElement("div");
      node.setAttribute("id", name);
      node.setAttribute("class", "videoBox1");
      var shadow = document.getElementById("shadow");
      shadow.appendChild(node);
      e.play(name);
    });
    this.Bus.$on("removelist", (e) => {
      var box = document.getElementById(e.getUserId());
      box.remove();
      this.list = this.list.filter((stream) => {
        return stream.userId !== e.getUserId();
      });
    });

    //远方流列表

    this.Bus.$on("micError", () => {
      this.$message.error(this.$t("您的设备没有麦克风或未授权，无法推音频流"));
    });
    //摄像机报错
    this.Bus.$on("cameraError", () => {
      this.$message.error(this.$t("您的设备没有摄像头或未授权，无法推视频流"));
    });
    //屏幕分享报错

    this.Bus.$on("shareScreen", (e) => {
      this.shareFlag = true;
      this.sharename = this.$chat.searchName(e.sharename);
    });

    this.Bus.$on("shareClose", (e) => {
      this.shareFlag = false;
      this.sharename = "";
      this.screenFlag = false;
    });
    this.Bus.$on("loginS", (e) => {
      this.auth = this.$chat.role.auth;
    });

    this.Bus.$on("mute", (e) => {
      if ((e == 0 && this.$chat.role.auth != 5) || e == this.$chat.role.id) {
        this.$message.error("主持人开启禁言");
        if (this.yuyinFlag) {
          this.yuyinFlag = !this.yuyinFlag;
          this.$client.muteAudio(this.yuyinFlag);
        }

        // if (this.shipinFlag) {
        //   this.shipinFlag = !this.shipinFlag;
        //   this.$client.muteVideo(this.shipinFlag);
        // }

        // if (this.screenFlag) {
        //   this.screenFlag = false;
        //   this.$client.shareScreen(false);
        // }
      }
    });

    this.Bus.$on("login", () => {
      setTimeout(() => {
        this.$client.clientFlag = true;
      }, 3000);

      // this.flag = true;

      this.$message({
        type: "success",
        message: "您已加入语音区间",
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.cFunction {
  display: flex;
  align-items: center;

  position: fixed;
  right: 0;
  bottom: 0px;
  width: 260px;
  height: 40.68px;

  background: linear-gradient(
    0deg,
    rgba(185, 195, 207, 0.9) 0%,
    rgba(255, 255, 255, 0.7) 100%
  );
  z-index: 1;
  .img1 {
    width: 30px;
    height: 30px;
    margin: 0 40px;
    cursor: pointer;
  }
  .active {
    color: #536b8e;
  }
}
.shadow {
  width: 210px;
  padding: 20px 25px;
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 40px);
  background: url("../assets/newImg/clientback.png");
  overflow-y: scroll;
  transition: 0.5s;
  z-index: 10001;
  .videoBox {
    width: 210px;
    height: 130px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background: url("../assets/newImg/clientback.png");
  }
  .pos {
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    top: 0px;
    width: 100%;
    transition: 0.5s;
    z-index: 9999;
  }
}

.qp {
  width: 80%;
  height: 80%;
  z-index: 10001;
}

.msg {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 40px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1001;
  .img {
    width: 30px;
    height: 30px;
    margin: 0 40px;
  }
  p {
    color: #3f4957;
    font-size: 12px;
    margin-left: 10px;
  }
  .iconList {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 10px;
    width: 20px;
    cursor: pointer;
  }
}
.img {
  width: 100%;
  height: 100%;
}
.close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 999;
}
.shadow::-webkit-scrollbar {
  width: 4px;
  opacity: 0.6;
}

.shadow::-webkit-scrollbar-track {
  background: #ffffff;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

#restream {
  position: absolute;
  width: 250px;
  padding: 20px 25px;
  position: absolute;
}
.shadow::-webkit-scrollbar {
  width: 0 !important;
}

.trans {
  width: 20px;
  position: absolute;
  right: 10px;
  transform: rotate(180deg);
  cursor: pointer;
  transition: 0.5s;
}

.trans1 {
  transform: rotate(0deg);
}

.client1 {
  position: fixed;
  right: 40px;
  top: 40px;
  width: 40px;
}
</style>
