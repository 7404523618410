import Hls from "hls.js";
import flvjs from "flv.js";
import * as THREE from "three/build/three.module";


class screenVideo {
  constructor() {
    this.videoElement = document.createElement('video');
    //this.videoElement.setAttribute('id','this.videoElement');
    this.videoElement.setAttribute('crossOrigin', 'Anonymous');
    this.videoElement.setAttribute('webkit-playsinline', 'true');
    this.videoElement.setAttribute('playsinline', 'true');
    this.videoElement.setAttribute('loop', 'true');
    this.videoTex = new THREE.VideoTexture(this.videoElement);
    this.videoTex = this.videoTex;
    this.isPlay = false;
    if (this.videoElement) {
      this.videoElement.oncanplay = () => {
        if (!this.isPlay) {
          if (this.curScreen) {
            this.curScreen.material.map = this.videoTex;
          }
          this.videoElement.play();
          this.videoElement.muted = false;
          this.isPlay = true;
        }

      };
    }
  }

  setScreen(screen) {
    if (screen != this.curScreen) {
      if (this.curScreen) {
        this.curScreen.material = this.oldMtl;
      }
      this.curScreen = screen;
      this.curMtl?.dispose();
      if (screen && screen.material) {
        this.oldMtl = screen.material;
        this.curMtl = screen.material.clone();
        this.curScreen.material = this.curMtl;
      }
    }
  }

  playVideo(url) {
    try {
      console.log(url)
      this.isPlay = false;
      this.videoElement.src = "";
      if (url.indexOf("video") != 0
        && this.videoTex.video != this.videoElement) {
        this.videoTex.dispose();
        this.videoTex = new THREE.VideoTexture(this.videoElement);
        //this.curMtl.map = this.videoTex;
      }
      if (url) {
        if (this.curScreen && this.curMtl) {
          this.curScreen.material = this.curMtl;
        }
        if (this.flvPlayer) {
          this.flvPlayer.detachMediaElement();
          this.flvPlayer.destroy();
          this.flvPlayer = null;
        }
        if (this.hlsPlayer) {
          this.hlsPlayer.detachMedia();
          this.hlsPlayer.destroy();
          this.hlsPlayer = null;
        }
        let bPlay = false;
        let arrStr = url.split("?");
        if (arrStr && arrStr.length > 0) {
          let len = arrStr[0].length;
          if (len < 4) return;
          let name = arrStr[0].substr(len - 3, 3);
          if (name == "flv" && flvjs.isSupported()) {
            this.flvPlayer = flvjs.createPlayer({ type: "flv", url: url });
            this.flvPlayer.attachMediaElement(this.videoElement);
            this.flvPlayer.load();
            this.flvPlayer.play();
            console.log(this.flvPlayer);
            bPlay = true;
          }
          else if (url.indexOf("mp4") >= 0) {
            this.videoElement.src = url;
            bPlay = true;
          }
          name = arrStr[0].substr(len - 4, 4);
          if (name == "m3u8") {
            // var is_mobile =
            //   navigator.userAgent
            //     .toLowerCase()
            //     .match(
            //       /(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i
            //     ) != null;
            let u = navigator.userAgent;


            var userAgentInfo = navigator.userAgent;
            var Agents = ["iPhone", "iPad"];
            var isiOS = false;
            for (var v = 0; v < Agents.length; v++) {
              if (userAgentInfo.indexOf(Agents[v]) > 0) {
                isiOS = true;
                break;
              }
            }
            // alert(`当前设备是${isiOS?'ios':'安卓'}`)
            if (isiOS) {
              this.videoElement.src = url; 
              bPlay = true;
            } else if (Hls.isSupported()) {
              this.hlsPlayer = new Hls();
              this.hlsPlayer.attachMedia(this.videoElement);
              this.hlsPlayer.loadSource(url);
              //this.videoElement.play();
              bPlay = true;
            }
          }
          if (!bPlay) {
            if (url.indexOf("video") == 0) {
              let video = document.getElementById(url);
              if (video) {
                this.videoTex.dispose();
                this.videoTex = new THREE.VideoTexture(video);
                this.curMtl.map = this.videoTex;
                this.videoElement.pause();
                return;
                //bPlay = true;
              }
            }
          }
        }
        if (bPlay) {
          this.videoElement.muted = true;
          //this.videoElement.play();
          return;
        }
      }
      if (this.curScreen && this.oldMtl) {
        this.curScreen.material = this.oldMtl;
      }
      this.videoElement.pause();
    } catch (e) {
      console.log(e);
    }
  }
}

export { screenVideo }